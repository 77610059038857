<template>
    <div class="listaEvacuatori" style="background-color: white;">
        <v-data-table :headers="headers"
                      :items="evacuatori"
                      :items-per-page="itemsPerPage"
                      :class="dataClass()"
                      :footer-props="{'items-per-page-text': 'Elementi Per Pagina', 'items-per-page-options': [10,15,-1], 'items-per-page-all-text': 'Tutti'}"
                      :header-props="{'sort-by-text': 'Ordina Per'}"
                      no-data-text="Nessun evacuatore"
                      @click:row="rowClick"
                      :search="search"
                      :hide-default-footer="hideFooter"
                      item-class="trimTextTooLong"
        >
            <template v-slot:top v-if="filterSearch">
                <v-text-field v-model="search"
                              label="Cerca"
                              hide-details="auto"
                              dense
                              class="mx-4 mt-0 pt-0 mb-1" />
            </template>
            <template v-slot:item.multipleSelecting="{item}">
                <v-checkbox dense @click="selectEvacuatore(item._id)" :input-value="item.multipleSelecting" />
            </template>
            <template v-slot:item.letteraScheda="{item}">
                <v-chip class="px-5 white--text" :color="item.coloreScheda">
                    {{ item.letteraScheda }}
                </v-chip>
            </template>
            <template v-slot:item.data_creazione_formatted="{item}">
                {{ formatDate(item.data_creazione_formatted) }}
            </template>
            <template v-slot:item.dataSostituzioneBombola_formatted="{item}">
                <span :class="checkDateMoreThan2Years(item.dataSostituzioneBombola_formatted)">
                  {{ formatDate(item.dataSostituzioneBombola_formatted) }}
                </span>
            </template>
            <template v-slot:item.dataUltimaApertura_formatted="{item}">
                <span :class="checkDateMoreThan4Years(item.dataUltimaApertura_formatted)">
                  {{ formatDate(item.dataUltimaApertura_formatted) }}
                </span>
            </template>
            <template v-slot:item.dataUltimoRapporto_formatted="{item}">
                <span :class="checkDateMoreThan_Months(item.dataUltimoRapporto_formatted)">
                  {{ formatDate(item.dataUltimoRapporto_formatted) }}
                </span>
            </template>
            <template v-slot:item.data_semestri="{item}"></template>
            <template v-slot:item.anni="{item,header,index}">
                <span v-if="Boolean(item['data_semestri'][item['data_semestri'].findIndex( x => x['anno'] == header['text'])]['valori'][0])"><v-icon small color="green">fas fa-check-circle</v-icon></span>
                <span v-else><v-icon small color="#ccc">fas fa-minus-circle</v-icon></span>
                &nbsp;
                <b v-if="Boolean(item['data_semestri'][item['data_semestri'].findIndex( x => x['anno'] == header['text'])]['valori'][1])"><v-icon small color="green">fas fa-check-circle</v-icon></b>
                <span v-else><v-icon small color="#ccc">fas fa-minus-circle</v-icon></span>
            </template>
        </v-data-table>
        <v-container grid-list-sm fluid v-if="multipleChosing" class="mt-3">
            <v-row>
                <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                    <v-btn color="primary" width="100%" @click="emitAndClose">Fatto</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <SchedaEvacuatore :_id="id_evacuatore_clicked" :id_stabilimento="id_stabilimento" v-if="id_evacuatore_clicked && id_stabilimento" @closeScheda="closeScheda" :hasPermission="hasPermission" :actionScheda="actionScheda" />
    </div>
</template>

<script>
import apiEvacuatori from '@/js/pages/evacuatori';
import apiStabilimenti from '@/js/pages/stabilimenti';
const SchedaEvacuatore = () => import('@/views/SchedaEvacuatore');
import {
    checkMoreThan2Years,
    checkMoreThan4Years,
    checkMoreThan6Months,
    checkMoreThan12Months,
    unixToItalian
} from '@/js/formatDate';

export default {
    name: 'listaEvacuatori',
    props: [
        'id_stabilimento',
        'viewEvacuatore',
        'isChosing',
        'multipleChosing',
        'lista_evacuatori',
        'list_selected',
        'isMaster',
        'actionScheda',
        'filterSearch',
        'hideFooter',
        'showSemestri',
        'filtroReparto'
    ],
    components: {
        SchedaEvacuatore
    },
    watch: {
        viewEvacuatore(){
            if(this.viewEvacuatore) this.showEvacuatore(this.viewEvacuatore);
        },
        async showSemestri() {
            if(this.showSemestri) {
                this.headers.push({
                    text: 'Semestri',
                    value: 'data_semestri'
                });
            } else {
                let index = this.headers.findIndex( x => x && x['text'] === 'Semestri');
                if(index !== -1) this.headers.splice(index, (this.headers.length-index));
            }
            await this.initElenco();
        },
        async filtroReparto() {
            await this.initElenco();
        }
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            schede: [],
            search: '',
            headers: [
                /*{
                    text: 'Lettera',
                    name: 'letteraScheda',
                    align: 'start',
                    value: 'letteraScheda',
                },*/
                {
                    text: 'Reparto',
                    name: 'repartoScheda',
                    align: 'start',
                    value: 'rNameReparto',
                }, {
                    text: 'Matricola',
                    value: 'matricola',
                }, {
                    text: 'Anno',
                    value: 'anno'
                }, {
                    text: 'Note',
                    value: 'note'
                }, {
                    text: 'Data Aggiunta',
                    value: 'data_creazione_formatted'
                }, {
                    text: 'Sostituzione Bombola',
                    value: 'dataSostituzioneBombola_formatted'
                }, {
                    text: 'Data Ultima Apertura',
                    value: 'dataUltimaApertura_formatted'
                }, {
                    text: 'Data Ultimo Rapporto',
                    value: 'dataUltimoRapporto_formatted'
                }
            ],
            evacuatori: [],
            id_evacuatore_clicked: undefined,
            array_evacuatori_clicked: [],
            itemsPerPage: 5,
            frequenza_controlli_mensile: true,
            headers_semestri: []
        }
    },
    computed: {
        hasPermission() {
            if(
                this.isMaster === undefined ||
                this.isMaster === null
            ) return true;
            else return this.isMaster;
        }
    },
    async created() {
        if(this.hideFooter) this.itemsPerPage = 500;
        if(this.multipleChosing) this.headers.splice(0, 0, { text: 'Seleziona', value: 'multipleSelecting' });
        if(
            this.list_selected &&
            this.list_selected.length
        ) this.array_evacuatori_clicked = [...this.list_selected];
        await this.initElenco();
        if(this.id_stabilimento) {
            let stabilimento = await apiStabilimenti.getOne(this.id_stabilimento);
            if(
                stabilimento &&
                stabilimento['frequenza_controlli'] == 'Mensile'
            ) {
                this.frequenza_controlli_mensile = true;
            } else this.frequenza_controlli_mensile = false;
        }
    },
    methods: {
        async initElenco() {
            let arr = [];
            if(
                this.lista_evacuatori &&
                this.lista_evacuatori.length
            ) arr = await apiEvacuatori.getAllFromList(this.token, this.lista_evacuatori, this.showSemestri);
            else if(this.id_stabilimento)
                arr = await apiEvacuatori.getAll(this.token, this.id_stabilimento, this.showSemestri, this.filtroReparto);
            if(arr) {
                if(this.multipleChosing) {
                    for (let item of arr) {
                        let pos = this.array_evacuatori_clicked.indexOf(item._id);
                        if(pos === -1) item.multipleSelecting = false;
                        else item.multipleSelecting = true;
                    }
                }
                if(this.showSemestri) {
                    for (const dato_semestre of arr[0].data_semestri) {
                        let anno = dato_semestre['anno'];
                        this.headers.push({
                            text: anno,
                            value: 'anni'
                        });
                    }
                }
                this.evacuatori = arr;
            }
        },
        rowClick(evacuatore){
            if(!this.isChosing){
                if(evacuatore) this.id_evacuatore_clicked = evacuatore._id;
                else this.id_evacuatore_clicked = undefined;
            } else if(!this.multipleChosing) this.$emit('evacuatoreClick', evacuatore);
        },
        async closeScheda(info) {
            if(info == 'reloadEvacuatori') await this.initElenco();
            this.id_evacuatore_clicked = undefined;
            this.$emit('closeScheda');
        },
        async getPointEvacuatore(punto) {
            if(
                punto &&
                punto._id
            ){
                let evacuatore = await apiEvacuatori.getOneFromPoint(this.token, punto._id);
                if(evacuatore) this.rowClick(evacuatore);
            }
        },
        showEvacuatore(id) {
            if(id) this.id_evacuatore_clicked = id;
        },
        selectEvacuatore(id) {
            if(id) {
                let pos = this.array_evacuatori_clicked.indexOf(id);
                if(pos === -1) this.array_evacuatori_clicked.push(id);
                else this.array_evacuatori_clicked.splice(pos, 1);
                if(this.multipleChosing) {
                    let index = this.evacuatori.findIndex(x => x._id == id);
                    if(index !== -1) this.evacuatori[index]['multipleSelecting'] = this.evacuatori[index]['multipleSelecting'] === undefined ? true : !this.evacuatori[index]['multipleSelecting'];
                }
            }
        },
        emitAndClose() {
            this.$emit('evacuatoriSelected', this.array_evacuatori_clicked);
        },
        dataClass() {
            let myClass = "trFixedWidth";
            if(this.hideFooter) myClass += ' mb-3';
            if(!this.filterSearch) myClass += ' hideMobileHeader';
            return myClass;
        },
        checkDateMoreThan2Years(data) {
            let result = checkMoreThan2Years(data);
            if(result === 1) return 'redDateContainer';
            else if(result === 2) return 'yellowDateContainer';
            else return '';
        },
        checkDateMoreThan4Years(data) {
            let result = checkMoreThan4Years(data);
            if(result === 1) return 'redDateContainer';
            else if(result === 2) return 'yellowDateContainer';
            else return '';
        },
        checkDateMoreThan_Months(data) {
            let result;
            if(this.frequenza_controlli_mensile) result = checkMoreThan6Months(data);
            else result = checkMoreThan12Months(data);
            if(result === 1) return 'redDateContainer';
            else if(result === 2) return 'yellowDateContainer';
            else return '';
        },
        formatDate(unixString) {
            return unixToItalian(unixString) || ' - ';
        }
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"listaEvacuatori",staticStyle:{"background-color":"white"}},[_c('v-data-table',{class:_vm.dataClass(),attrs:{"headers":_vm.headers,"items":_vm.evacuatori,"items-per-page":_vm.itemsPerPage,"footer-props":{'items-per-page-text': 'Elementi Per Pagina', 'items-per-page-options': [10,15,-1], 'items-per-page-all-text': 'Tutti'},"header-props":{'sort-by-text': 'Ordina Per'},"no-data-text":"Nessun evacuatore","search":_vm.search,"hide-default-footer":_vm.hideFooter,"item-class":"trimTextTooLong"},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([(_vm.filterSearch)?{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 mt-0 pt-0 mb-1",attrs:{"label":"Cerca","hide-details":"auto","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}:null,{key:"item.multipleSelecting",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","input-value":item.multipleSelecting},on:{"click":function($event){return _vm.selectEvacuatore(item._id)}}})]}},{key:"item.letteraScheda",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-5 white--text",attrs:{"color":item.coloreScheda}},[_vm._v(" "+_vm._s(item.letteraScheda)+" ")])]}},{key:"item.data_creazione_formatted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.data_creazione_formatted))+" ")]}},{key:"item.dataSostituzioneBombola_formatted",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.checkDateMoreThan2Years(item.dataSostituzioneBombola_formatted)},[_vm._v(" "+_vm._s(_vm.formatDate(item.dataSostituzioneBombola_formatted))+" ")])]}},{key:"item.dataUltimaApertura_formatted",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.checkDateMoreThan4Years(item.dataUltimaApertura_formatted)},[_vm._v(" "+_vm._s(_vm.formatDate(item.dataUltimaApertura_formatted))+" ")])]}},{key:"item.dataUltimoRapporto_formatted",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.checkDateMoreThan_Months(item.dataUltimoRapporto_formatted)},[_vm._v(" "+_vm._s(_vm.formatDate(item.dataUltimoRapporto_formatted))+" ")])]}},{key:"item.data_semestri",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.anni",fn:function(ref){
var item = ref.item;
var header = ref.header;
var index = ref.index;
return [(Boolean(item['data_semestri'][item['data_semestri'].findIndex( function (x) { return x['anno'] == header['text']; })]['valori'][0]))?_c('span',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("fas fa-check-circle")])],1):_c('span',[_c('v-icon',{attrs:{"small":"","color":"#ccc"}},[_vm._v("fas fa-minus-circle")])],1),_vm._v(" "),(Boolean(item['data_semestri'][item['data_semestri'].findIndex( function (x) { return x['anno'] == header['text']; })]['valori'][1]))?_c('b',[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("fas fa-check-circle")])],1):_c('span',[_c('v-icon',{attrs:{"small":"","color":"#ccc"}},[_vm._v("fas fa-minus-circle")])],1)]}}],null,true)}),(_vm.multipleChosing)?_c('v-container',{staticClass:"mt-3",attrs:{"grid-list-sm":"","fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"4","offset-md":"4","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary","width":"100%"},on:{"click":_vm.emitAndClose}},[_vm._v("Fatto")])],1)],1)],1):_vm._e(),(_vm.id_evacuatore_clicked && _vm.id_stabilimento)?_c('SchedaEvacuatore',{attrs:{"_id":_vm.id_evacuatore_clicked,"id_stabilimento":_vm.id_stabilimento,"hasPermission":_vm.hasPermission,"actionScheda":_vm.actionScheda},on:{"closeScheda":_vm.closeScheda}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }